import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { GlobalVariable } from '../globals';

@Injectable({
  providedIn: 'root'
})
export class ListPageService {
  paramData:any;
  accessToken=localStorage.getItem('refreshToken');

  constructor(private http:HttpClient) { }

  getOrderList(vendorKey: string) {
    let headers = new HttpHeaders({
      Authorization: "Bearer "+this.accessToken,
      
    });
  
    // console.log("access", headers);
  
    this.paramData = {
      vendorKey: vendorKey
    };
  
    return this.http.post(`${GlobalVariable.BASE_API_URL_VENDOR}/order/getAllOrders`,this.paramData,{headers});
  }
}
