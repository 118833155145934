import { FuseNavigation } from "@fuse/types";

export const navigation: FuseNavigation[] = [
    {
        id: "vendor-autoverse-po-list",
        title: "Order List",
        translate: "NAV.ASP.TITLE",
        type: "item",
        icon: "store",                   
        url: "/order-list",
    },
    
    
];
