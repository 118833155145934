<div class="main-wrapper">
  <div class="container">
 

<!-- Vehicle Details Section -->
<mat-grid-list [cols]="gridCols" rowHeight="100px" gutterSize="16px">
  <mat-grid-tile rowspan="1" [colspan]="dynamicColspan">
    <div class="vehicle-details">
      <img *ngIf="vehicleDetails.make; else noLogo" 
          [src]="'https://cube.getpitstop.com/assets/img/car_make/' + vehicleDetails.make.replace(' ', '').toLowerCase() + '.png'" 
          alt="Vehicle Logo" 
          class="vehicle-logo">
          <ng-template #noLogo>
            <mat-icon class="logo-image" style="font-size:35px;">directions_car</mat-icon>
          </ng-template>
          <div class="vehicle-info">
            <!-- <p class="vehicle-name">{{ vehicleDetails.make }}</p> -->
          
            <p class="vechile-variant">{{ vehicleDetails.variantKey }},[{{vehicleDetails.year}}]</p>
            <p class="vechile-regno">{{ vehicleDetails.registrationNumber }}</p>
            <p class="vehicle-model">{{ vehicleDetails.model }}</p>
          </div>
          <div 
        >
          <p [ngClass]="{
            'cancelled': vehicleDetails.status == '0',
            'created': vehicleDetails.status == '1',
            'confirmed': vehicleDetails.status == '2',
            'ready-to-pick': vehicleDetails.status == '3',
            'dispatched': vehicleDetails.status == '4'
          }" >{{ getStatusName(vehicleDetails.status) }}</p>
          <p class="date-time">{{ vehicleDetails.orderCreated | date:'dd MMM yyyy, hh:mm a' }}</p> 
        </div>
       
        
          </div>

    
  </mat-grid-tile>
</mat-grid-list>

<!-- Data Table Section -->
<!-- <mat-grid-list [cols]="gridCols" rowHeight="200px" gutterSize="16px"> -->
  <!-- <mat-grid-tile rowspan="1" [colspan]="dynamicColspan"> -->
    <!-- <div class="data-table"> -->
      <h2>Spare Items</h2>
      <table mat-table class="data-table">
        <thead>
          <tr>
            <th>Name</th>
            <th>Brand</th>
            <th>Qty</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let element of tableData">
            <td>{{ element.name }}</td>
            <td>{{ element.brand }}</td>
            <td>{{ element.qty }}</td>
          </tr>
        </tbody>
      </table>
    <!-- </div> -->
  <!-- </mat-grid-tile>
</mat-grid-list> -->


<mat-grid-list [cols]="gridCols" rowHeight="fit" gutterSize="16px">

 
    <mat-card class="upload-card">
      <!-- Add Image Button in the Top-Right Corner -->
      <div class="card-header">
        <!-- <button mat-raised-button >Add Image</button> -->
         <p class="add-img" style="font-size:12px">Add Images</p>
        <mat-icon class="camera" (click)="fileInput.click()">photo_camera</mat-icon>
        <input
          type="file"
          #fileInput
          (change)="onFileSelected($event)"
          accept="image/*"
          multiple
          style="display: none;"
        />
      </div>
  
      <!-- Display Selected Image Previews -->
      <div class="image-preview-container">
        <div *ngIf="imagePath?.length === 0" class="no-image-text" >
          <p>No image selected</p>
        </div>
        <div class="image-wrapper" *ngFor="let imageUrl of imagePath">
          <!-- Cross Icon -->
          <button mat-icon-button class="delete-icon" (click)="removeImage(imageUrl)">
            <mat-icon style="margin-top:-35px;margin-left: -8px;" color="warn">close</mat-icon>
          </button>
          <!-- Image Preview -->
          <img [src]="imageUrl" alt="Selected Image" class="image-preview" />
        </div>
      </div>
  
    </mat-card>
    <!-- Mat Card for Upload Invoice -->
<mat-card class="upload-invoice-card">
  <!-- Card Header with Title -->
  <div class="card-header1">
    <p class="add-img1" style="font-size:12px">Retailer Invoice</p>
 
    <!-- <input
      type="file"
      #invoiceInput
      (change)="onInvoiceSelected($event)"
      accept="application/pdf"
      style="display: none;"
    /> -->

    <input
      type="file"
      #invoiceInput
      accept="application/pdf"
      style="display: none;"
    />
  </div>

  <!-- Display Selected Invoice Name -->
  <div class="invoice-preview-container">
    <div *ngIf="!invoicePath" class="no-invoice-text">
      <p>No invoice selected</p>
    </div>
    <div *ngIf="invoicePath && invoicePath?.length>0" class="invoice-wrapper">
      <mat-icon class="pdf-icon" color="warn">picture_as_pdf</mat-icon>
      <span class="invoice-name" (click)="openInvoice(invoicePath)">Retailer_Invoice_{{ invoiceNumber }}</span>
      <!-- Delete Button -->
      <button mat-icon-button *ngIf="vehicleDetails.status<=2">
        <mat-icon style="background-color: light gray; margin-top: -10px;" (click)="onDeleteInvoice(invoicePath)">delete</mat-icon>
      </button>
    </div>
  </div>
</mat-card>


    <!-- PDF Section -->
    <!-- <div *ngIf="uploadedInvoiceName" class="uploaded-invoice">
      <div class="invoice-wrapper">
        
        <mat-icon class="pdf-icon" color="warn">picture_as_pdf</mat-icon>

      
        <span class="invoice-name" (click)="openInvoice()">{{ uploadedInvoiceName }}</span>

        <button mat-icon-button color="warn" >
          <mat-icon (click)="onDeleteInvoice()">delete</mat-icon>
        </button>
      </div>
    </div>-->

  <!-- Conditionally Render Buttons -->
  <!-- <mat-grid-tile class="invoice-btn" rowspan="1" [colspan]="dynamicColspan"> -->
    
    <button
      mat-raised-button
      color="accent"
      class="create-payment-link"
      *ngIf="vehicleDetails.status == '1'"
      (click)="showUploadPopup = true"
    >
      Upload Invoice
    </button>

    <button
      mat-raised-button
      color="accent"
      class="create-payment-link"
      *ngIf="vehicleDetails.status == '2'"
      (click)="onMarkReadyToShip()"
    >
      Ready to Pick
    </button>

    <button
      mat-raised-button
      color="accent"
      class="create-payment-link"
      *ngIf="vehicleDetails.status == '3'"
      (click)="onMarkDispatched()"
    >
      Dispatched
    </button>

   
    
  <!-- </mat-grid-tile> -->
</mat-grid-list>

<!-- Upload Invoice Popup -->
<div *ngIf="showUploadPopup" class="popup-overlay">

  <div class="popup">
    <div class="cancel">
      <button style="float: right;margin-top: -3%;" mat-icon-button color="warn" (click)="showUploadPopup = false">
        <mat-icon>close</mat-icon>
      </button>
    </div>

    <h2>Upload Invoice</h2>

    <!-- Invoice Form -->
    <form [formGroup]="invoiceForm" >
      <!-- Invoice Date -->
      <mat-form-field appearance="outline" class="form-field" >
        <mat-label>Invoice Date
          <span class="required-asterisk">*</span>
        </mat-label>
        <input matInput type="date" formControlName="invoiceDate" />
        <mat-error *ngIf="invoiceForm.get('invoiceDate')?.hasError('required')">
          Invoice Date is required.
        </mat-error>
      </mat-form-field>

      <!-- Invoice Number -->
      <mat-form-field appearance="outline" class="form-field">
        <mat-label>Invoice Number
          <span class="required-asterisk">*</span>
        </mat-label>
        <input matInput type="text" formControlName="invoiceNumber" />
        <mat-error *ngIf="invoiceForm.get('invoiceNumber')?.hasError('required')">
          Invoice Number is required.
        </mat-error>
        <mat-error *ngIf="invoiceForm.get('invoiceNumber')?.hasError('pattern')">
          Invoice Number must be a valid number.
        </mat-error>
      </mat-form-field>

      <!-- File Input -->
      <input
        type="file"
        (change)="onPdfSelected($event)"
        formControlName="invoiceFile"
        class="file-input"
      />
      <mat-error  class="invoice-required" *ngIf="invoiceForm.get('invoiceFile')?.hasError('required') && !uploadedInvoiceName">
        Invoice file is required.
      </mat-error>

      <!-- Popup Actions -->
      <div class="popup-actions">
        <button mat-raised-button class="upload" color="primary" #pdfInput (click)="uploadInvoice()">Upload</button>
        <!-- <button mat-raised-button color="warn" (click)="showUploadPopup = false">Cancel</button> -->
      </div>
    </form>
  </div>
</div>

</div>
</div>