import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class S3ImageUploadService {
  uploadInvoice(updatedFormData: any) {
    throw new Error('Method not implemented.');
  }
  private presignedUrlApi = 'https://iapi.testpitstop.com/v1.0/shipment/V3/presignedurl/create';
  // private presignedUrlApi = 'https://iapi.testpitstop.com/v1.0//pitstopV2/v2/presignedurl/create';

  constructor(private http:HttpClient) { }

  getPresignedUrl(fileName): Observable<any> {
    const headers = new HttpHeaders({
      'x-api-key': 'h6S5lcvELn2XLh8XURiFW1HSynljQywm6lbH1Yf7',
      'Accept': 'application/json',
      'Content-Type': 'application/json',
    });
    const body = [fileName];
  
    return this.http.post<any>(this.presignedUrlApi, body, { headers });
  }
}
