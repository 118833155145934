<div [attr.id]="isMobileDisabled ? 'loginMobile' : 'login'" class="inner-scroll full-page" fxLayout="row" fxLayout.sm="column" fxLayout.xs="column" fxLayoutAlign="center">
  <!-- Introductory Section -->
  <div id="login-intro" [ngClass]="{'login-introMobile': isMobileDisabled}">
    <div class="title" [ngClass]="{'titleMobile': isMobileDisabled}">Your One-Stop Solution for Genuine Auto Parts</div>
    <div class="description">
      Find High-Quality Parts for All Your Needs - Fast, Reliable, and Affordable!
    </div>
  </div>
  <mat-grid-list cols="gridCols" rowHeight="fit" class="full-height-grid">
    <!-- Left Tile: Login Form -->
    <mat-grid-tile>
      <div id="login-form-wrapper" fusePerfectScrollbar>
        <div id="login-form">
          <div class="title">
            <h2 style="font-weight: bold; align-items: center;">Login</h2>
          </div>

          <!-- Mobile Number Input Form -->
          <form *ngIf="!enableOTP" [formGroup]="requestOTPForm" (ngSubmit)="requestOTP()" novalidate>
            <mat-form-field appearance="outline" class="full-width">
              <mat-label>Mobile Number</mat-label>
              <input #mobileInput matInput formControlName="mobile" maxlength="10">
              <mat-icon matSuffix class="secondary-text">phone</mat-icon>
              <mat-error *ngIf="requestOTPForm.get('mobile').hasError('required')">
                Mobile Number is required
              </mat-error>
              <mat-error *ngIf="requestOTPForm.get('mobile').hasError('pattern')">
                Not a valid mobile number
              </mat-error>
              <mat-error *ngIf="!requestOTPForm.get('mobile').hasError('pattern') && requestOTPForm.get('mobile').hasError('minlength')">
                Mobile Number is less than 10 digits
              </mat-error>
            </mat-form-field>
            <div class="button-wrapper">
              <button mat-raised-button style="background-color: #144579;" color="accent" class="submit-button" aria-label="Request OTP" [disabled]="requestOTPForm.invalid">
                Request OTP
              </button>
            </div>
          </form>

          <!-- OTP Input Form -->
          <form *ngIf="enableOTP" [formGroup]="loginForm" (ngSubmit)="login()" novalidate>
            <mat-form-field appearance="outline" class="full-width">
              <mat-label>Mobile Number</mat-label>
              <input #mobileInput matInput formControlName="mobile" maxlength="10">
              <mat-icon matSuffix class="secondary-text">phone</mat-icon>
              <mat-error *ngIf="requestOTPForm.get('mobile').hasError('required')">
                Mobile Number is required
              </mat-error>
              <mat-error *ngIf="requestOTPForm.get('mobile').hasError('pattern')">
                Not a valid mobile number
              </mat-error>
              <mat-error *ngIf="!requestOTPForm.get('mobile').hasError('pattern') && requestOTPForm.get('mobile').hasError('minlength')">
                Mobile Number is less than 10 digits
              </mat-error>
            </mat-form-field>
            <div class="changeMob">
              <a class="change-mobile-link" style="color: #144579;" (click)="changeMobileNumber()">Change mobile number</a>
            </div>

            <mat-form-field appearance="outline" class="otp-input">
              <mat-label>OTP</mat-label>
              <input matInput formControlName="otp" maxlength="6" [type]="hide ? 'text' : 'password'" autocomplete="false">
              <mat-icon matSuffix (click)="hide = !hide">{{hide ? 'visibility' : 'visibility_off'}}</mat-icon>
              <mat-error *ngIf="loginForm.get('otp').hasError('required')">
                OTP is required
              </mat-error>
              <mat-error *ngIf="loginForm.get('otp').hasError('minlength')">
                OTP must be 6 digits
              </mat-error>
            </mat-form-field>

            <div *ngIf="countdown > 0" class="otp-timer">
              Not received the OTP <span style="color: #144579;">{{countdown}} seconds</span>
            </div>
            <div *ngIf="countdown === 0" class="otp-timer">
              Not received the OTP <a (click)="resendOTP()">Resend OTP</a>
            </div>

            <div class="button-wrapper">
              <button mat-raised-button style="background-color: #144579;" color="accent" class="submit-button" aria-label="LOGIN" [disabled]="loginForm.invalid">
                Login
              </button>
            </div>
          </form>
        </div>
      </div>
    </mat-grid-tile>
  </mat-grid-list>
</div>
