import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { ListPageService } from './list-page.service';

@Component({
  selector: 'app-list-page',
  templateUrl: './list-page.component.html',
  styleUrls: ['./list-page.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ListPageComponent implements OnInit {
  searchText: string = '';
  filteredOrders: any[];
  filterForm = new FormGroup({
    date: new FormControl(),
  });
  orders: any;

  constructor(
    private router: Router,
    private listPageService: ListPageService
  ) {}

  ngOnInit(): void {
    this.checkLogin();
    localStorage.removeItem('pageHeaders');
    this.getOrders();
    this.filterForm.get('date')?.valueChanges.subscribe((selectedDate) => {
      this.filterOrdersByDate(selectedDate);
    });

    (document.getElementsByClassName('toolbar-back-button')[0] as HTMLInputElement).innerHTML = '';
    (document.getElementsByClassName('toolbar-title')[0] as HTMLInputElement).innerHTML = 'Purchase Orders';
    (document.getElementsByClassName('toolbar-sub-title')[0] as HTMLInputElement).innerHTML = '';
          
  }

  checkLogin(){
    let spareLoginToken = localStorage.getItem('vendorKey');
    if(!spareLoginToken){
      this.router.navigate(['login']);
    }
  }

  getStatusName(statusCode: number): string {
    // console.log('getStatusName called with:', statusCode);
    const statusMap = {
      0: 'CANCELLED',
      1: 'CREATED',
      2: 'CONFIRMED',
      3: 'READY TO PICK',
      4: 'DISPATCHED',
    };
    return statusMap[statusCode] || 'UNKNOWN';
  }
 
  getOrders() {
    const vendorKey = localStorage.getItem('vendorKey');
    this.listPageService.getOrderList(vendorKey).subscribe(
      (response) => {
        const res = JSON.parse(JSON.stringify(response));
        if (res.code === 200) {
          this.orders = res.data.data || []; // Full list of orders
          this.filteredOrders = [...this.orders]; 
          // console.log("this.filteredOrders---",this.filteredOrders);
          const sortedData = this.filteredOrders.sort((a: any, b: any) => {
            return new Date(b.created).getTime() - new Date(a.created).getTime();
          });

         
          // console.log("list-",sortedData);
          // console.log("orders-",this.filteredOrders[0].orderKey);
          // localStorage.setItem('orderKey',this.filteredOrders[0].orderKey);
        }
      },
      (error) => {
        // console.error('Error fetching orders:', error);
        this.orders = [];
        this.filteredOrders = [];
      }
    );
  }
  

  // clearSearch() {
  //   this.searchText = '';
  //   // this.getOrders();
  // }

  filterOrdersBySearch() {
    if (!this.searchText) {
      this.getOrders();
      return;
    }
  
    const searchTextLower = this.searchText.toLowerCase();
  
    // Mapping supplier status codes to status names
    const statusMap = {
      0: 'CANCELLED',
      1: 'CREATED',
      2: 'CONFIRMED',
      3: 'READY TO PICK',
      4: 'DISPATCHED',
    };
  
    this.filteredOrders = this.orders.filter((order) => {
      const statusName = statusMap[order.supplierStatus] || 'UNKNOWN'; // Get status name for the code
      return (
        (order.itemNames?.join(', ') || '').toLowerCase().includes(searchTextLower) ||
        (order.id?.toString() || '').toLowerCase().includes(searchTextLower) ||
        (order.make?.toString() || '').toLowerCase().includes(searchTextLower) ||
        (order.model?.toString() || '').toLowerCase().includes(searchTextLower) ||
        (order.registraionNumber?.toString() || '').toLowerCase().includes(searchTextLower) ||
        statusName.toLowerCase().includes(searchTextLower) // Check against the hardcoded status name
      );
    });
  }
  
  

  filterOrdersByDate(selectedDate: Date | null) {
    if (!this.orders || this.orders.length === 0) {
      // If orders are not yet initialized, return early
      return;
    }
  
    if (!selectedDate) {
      // Clear the date from the search bar
      this.searchText = '';
      this.filteredOrders = [...this.orders]; // Reset to the full list
      return;
    }
  
    const selectedDateString = selectedDate.toLocaleDateString('en-GB', {
      day: '2-digit',
      month: 'short',
      year: 'numeric',
    });
  
    // Update the search bar with the selected date
    this.searchText = selectedDateString;
  
    // Filter the orders for the selected date
    const matchingOrders = this.orders.filter((order) => {
      const orderDate = new Date(order.created).toLocaleDateString('en-GB', {
        day: '2-digit',
        month: 'short',
        year: 'numeric',
      });
      return orderDate === selectedDateString;
    });
  
    if (matchingOrders.length > 0) {
      // If matching orders are found, update the filtered list
      this.filteredOrders = matchingOrders;
    } else {
      // If no matching orders, show an empty list to display "No orders found"
      this.filteredOrders = [];
    }
  }
  
  
  clearSearch() {
    this.searchText = '';
    this.filterForm.get('date')?.setValue(null); 
    this.filteredOrders = [...this.orders]; 
  }
  
  
  viewDetails(key) {
    this.router.navigate(['order-details/'+key]);
    
  }
  goToMyRequest() {
    this.clearSearch();
    // this.router.navigate(['order-list']); 
  }
  
  
}
