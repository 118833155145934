let options: any;
if (window.location.host === "vendor.autoversemobility.in") {
    options = {
        BASE_API_URL_VENDOR:"https://iapi.getpitstop.com/v1.0/autoverse/vendor",
        
    };
} else if (window.location.host.indexOf("test.vendor.autoversemobility.in") > -1) {
    options = {
        BASE_API_URL_VENDOR:"https://iapi.testpitstop.com/v1.0/autoverse/vendor",
    };
} else {
    options = {
        BASE_API_URL_VENDOR:"https://iapi.testpitstop.com/v1.0/autoverse/vendor",
    };
}
export const GlobalVariable = Object.freeze(options);