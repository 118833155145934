import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { FuseConfigService } from '@fuse/services/config.service';
import { LoginService } from './login.service';
import { Router } from '@angular/router';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { ToastrService } from 'ngx-toastr';
import { Subject, timer, Subscription } from 'rxjs';
import { interval } from 'rxjs';
import { takeWhile } from 'rxjs/operators';

export interface Tile {
  color: string;
  cols: number;
  rows: number;
  text: string;
}

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  requestOTPForm: FormGroup;
  loginForm: FormGroup;
  isMobileDisabled: boolean = false;
  enableOTP: boolean = false; // Flag to toggle OTP section
  hide: boolean = true; // Controls password visibility in OTP field
  countdown: number = 60; // Countdown timer for OTP resend
  gridCols: number = 4; // Default grid columns
  tiles = Array.from({ length: 12 }, (_, i) => `Tile ${i + 1}`);
  mobile: string;
  timerSubscription: Subscription;
  previousOTP: string = '';
  constructor(
    private _fuseConfigService: FuseConfigService,
    private _formBuilder: FormBuilder,
    private loginService: LoginService,
    private router: Router,
    private breakpointObserver: BreakpointObserver,
    private toastr: ToastrService, 
  ) {}

  ngOnInit(): void {
    this.loginService.thisCheckLogin();
    this.removeMobileFromLocalStorage();
    this._fuseConfigService.config = {
      layout: {
        navbar: { hidden: true },
        toolbar: { hidden: true },
        footer: { hidden: true },
        sidepanel: { hidden: true }
      }
     
    };

    
    // Responsive grid adjustments
    this.breakpointObserver.observe([
      Breakpoints.XSmall,
      Breakpoints.Small,
      Breakpoints.Medium,
      Breakpoints.Large
    ]).subscribe(result => {
      if (result.matches) {
        if (result.breakpoints[Breakpoints.XSmall]) {
          this.gridCols = 1;
        } else if (result.breakpoints[Breakpoints.Small]) {
          this.gridCols = 2;
        } else if (result.breakpoints[Breakpoints.Medium]) {
          this.gridCols = 3;
        } else if (result.breakpoints[Breakpoints.Large]) {
          this.gridCols = 4;
        }
      }
    });

    // Initialize forms
    this.requestOTPForm = this._formBuilder.group({
      mobile: [this.getMobileFromLocalStorage(), [Validators.required, Validators.pattern(/^-?(0|[1-9]\d*)?$/), Validators.minLength(10)]]
    });

    this.loginForm = this._formBuilder.group({
      mobile: [this.getMobileFromLocalStorage(), [Validators.required, Validators.pattern(/^-?(0|[1-9]\d*)?$/), Validators.minLength(10)]],
      otp: ['', [Validators.required, Validators.minLength(6)]]
    });
  }
 

  // Retrieve mobile number from local storage
  private getMobileFromLocalStorage(): string {
    this.mobile=localStorage.getItem('mobile');
    return localStorage.getItem('mobile') || '';
  }

  // Store mobile number in local storage
  private setMobileInLocalStorage(mobile: string): void {
    localStorage.setItem('mobile', mobile);
  }

  // Remove mobile number from local storage
  private removeMobileFromLocalStorage(): void {
    localStorage.removeItem('mobile');
  }

  get rf() { return this.requestOTPForm.controls; }

  // Request OTP
  requestOTP() {
    if (this.requestOTPForm.valid) {
      this.enableOTP = true;
      this.startCountdown();
      const mobile = this.requestOTPForm.get('mobile').value;

      // Store mobile number in local storage
      this.setMobileInLocalStorage(mobile);

      // Set the mobile number in the loginForm after storing it in local storage
      this.loginForm.get('mobile').setValue(mobile);

      this.loginService.getOTP(mobile).subscribe(
        response => {
          this.loginForm = this._formBuilder.group({
            mobile: [{value: this.rf.mobile.value, disabled: this.isMobileDisabled}],
            otp: ['', [Validators.required, Validators.minLength(6)]]
          });
          console.log('OTP sent successfully:', response);
          
        },
        error => {
          console.error('Error sending OTP:', error);
          this.toastr.error('Mobile Number is Invalid');

        }
      );
    }
  }

  login() {
    if (this.loginForm.valid) {
      const mobile = this.loginForm.get('mobile').value;
      const otp = this.loginForm.get('otp').value;

      this.loginService.login(mobile, otp).subscribe(
        response => {
          // console.log("Kiran",response['data['vendorKey']'])
          if(response['code'] === 200) {
           
            console.log('Login successful:', response);
            let zohoId = response['data']['zohoId'];
            localStorage.setItem('zohoId', zohoId); 
            localStorage.setItem("vendorKey",response['data']['vendorKey'])
            let accessToken = response['data']['accessToken'];
            localStorage.setItem('accessToken', accessToken);  
            let refreshToken = response['data']['refreshToken'];
            localStorage.setItem('refreshToken', refreshToken);  
            this.toastr.success('You are successfully logged in', 'Login');
            this.removeMobileFromLocalStorage();
            this.router.navigate(['order-list']);
            
          }
        },
        error => {
          console.error('Login failed:', error);
          this.toastr.error('Invalid OTP');
        }
      );
    }
  }
  startTimer() {
    if (this.timerSubscription) {
      this.timerSubscription.unsubscribe();
    }
    
    this.countdown = 60;
    this.timerSubscription = interval(1000)
      .pipe(takeWhile(() => this.countdown > 0))
      .subscribe(() => {
        this.countdown--;
      });
  }
  ngAfterViewInit(){
    const mobileInput = document.querySelector('input[formControlName="mobile"]');
  
    if (mobileInput) {
      mobileInput.addEventListener('focus', () => {
    
        window.scrollBy({
          top: -(window.innerHeight * 0.1), 
          behavior: 'smooth' 
        });
      });
    }
  }
  

   // Function to resend OTP and reset countdown
   resendOTP() {
    if (this.loginForm.get('otp').value) {
      this.previousOTP = this.loginForm.get('otp').value; // Save the previous OTP
    }

    // Clear the OTP field
    this.loginForm.get('otp').setValue('');

    // Temporarily display the previous OTP after a brief delay
    setTimeout(() => {
      this.loginForm.get('otp').setValue(this.previousOTP);
    }, 1000);

    // Start countdown and resend OTP
    this.startCountdown();
    this.startTimer(); alert('starttimer')
    this.requestOTP();
  }


  // Function to change mobile number
  changeMobileNumber() {
    this.enableOTP = false;
    this.requestOTPForm.controls['mobile'].setValue(this.loginForm.controls['mobile'].value);
  }

  startCountdown() {
    this.countdown = 60;
    const interval = setInterval(() => {
      this.countdown--;
      if (this.countdown <= 0) {
        clearInterval(interval);
      }
    }, 1000);
  }
  
}
