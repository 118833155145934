<mat-toolbar>
    <button *ngIf="isMobile"  mat-icon-button class="example-icon" aria-label="Example icon-button with menu icon" (click)="toggleSidebarOpen('navbar')" fxHide.gt-md>
      <mat-icon>menu</mat-icon>
    </button>
    <div class="page-headers">
      <mat-icon class="toolbar-back-button" [routerLink]="['order-list']"></mat-icon>
      <span class="toolbar-title"></span>    
      <span class="example-spacer"></span>
      <span class="toolbar-sub-title"></span>
    </div>
</mat-toolbar>